import { Controller } from "@hotwired/stimulus"
import getConsumer from "../helpers/consumer"
import channelFunctions from "../helpers/spot_actions"

export default class extends Controller {
  static targets = ["spotData", "spotlight"]

  initialize() {
    this.placeholderHTML = this.spotlightTarget.innerHTML
  }

  connect() {
    // Make the channel functions have a reference to the stimulus controller
    channelFunctions.controller = this;

    this.consumer = getConsumer()
    if (!this.spotChannel) {
      this.spotChannel = this.consumer.subscriptions.create({channel: "SpotChannel"}, channelFunctions)
    }
  }

  disconnect() {
    if (this.spotChannel) {
      this.spotChannel.unsubscribe()
      this.spotlightTarget = this.placeholderHTML.innerHTML
    }
  }

  startPlaying() {
    if (this.hasSpotDataTarget) {
      const mediaQueue = this.spotDataTargets.map((htmlElement) => {
        return { mediaUrl: htmlElement.dataset.mediaUrl, mediaType: htmlElement.dataset.mediaType }
      })
      this.playMediaInIntervals(mediaQueue, null);
    } else {
      // TODO: handle the case where you don't play anything
    }
  }

  playMediaInIntervals(queue = [], mediaObject = null) {
    if (this.mediaPlayingInterval) {
      clearInterval(this.mediaPlayingInterval)
    }

    if (!mediaObject) {
      mediaObject = queue[0]
      this.clearSpotlight()
      this.putMediaOnSpotlight(mediaObject)
    } else {
      this.clearSpotlight()
      this.putMediaOnSpotlight(mediaObject)
    }

    const nextMediaObjectIndex = queue.findIndex(obj => obj.mediaUrl === mediaObject.mediaUrl) + 1
    const nextMediaObject = queue[nextMediaObjectIndex]

    // If the current media playing is a video, wait until the video finishes and then play the next media
    if (mediaObject && mediaObject.mediaType === "video") {
      const vidOnSpotlight = this.spotlightTarget.querySelector("video")
      vidOnSpotlight.addEventListener("ended", () => {
        this.putMediaOnSpotlight()
        this.playMediaInIntervals(queue, nextMediaObject)
      }, { once: true })

      return
    }

    // Out of bounds, loop back to the start
    if (!queue[nextMediaObjectIndex]) {
      this.mediaPlayingInterval = setInterval(() => {
        this.clearSpotlight()
        this.putMediaOnSpotlight(queue[0])
        this.playMediaInIntervals(queue, nextMediaObject)
      }, 5000)
    }
    // Proceed to the next media
    else {
      this.mediaPlayingInterval = setInterval(() => {
        this.clearSpotlight()
        this.putMediaOnSpotlight(nextMediaObject)
        this.playMediaInIntervals(queue, nextMediaObject)
      }, 5000)
    }

  }

  putMediaOnSpotlight(mediaObject) {
    if (!mediaObject) {
      return this.clearSpotlight()
    }

    switch (mediaObject.mediaType) {
      case "image":
        this.putImageOnSpotlight(mediaObject.mediaUrl)
        break
      case "video":
        this.putVideoOnSpotlight(mediaObject.mediaUrl)
        break
      default:
        this.clearSpotlight()
    }
  }

  putImageOnSpotlight(mediaUrl) {
    const img = document.createElement("img")
    img.src = mediaUrl
    img.classList.add("img-fluid")

    this.spotlightTarget.append(img)
  }

  putVideoOnSpotlight(mediaUrl) {
    const vid = document.createElement("video")
    vid.src = mediaUrl
    vid.controls = true
    vid.autoplay = true
    vid.muted = false

    this.spotlightTarget.append(vid)
  }

  clearSpotlight() {
    this.spotlightTarget.innerHTML = ""
  }

  get isVideoOnSpotlight() {
    return !!this.spotlightTarget.querySelector("video")
  }
}